<template>
  <div class="warp">
    <van-field
      v-model="query.receiveComName"
      center
      clearable
      label="接收单位"
      placeholder="请输入接收单位">
      <template #button>
        <van-button size="small" type="primary" @click="queryClick">查询</van-button>
      </template>
    </van-field>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad">
      <div class="item-list" v-for="(item, index) in itemList" :key="index">
        <p class="p1">接收单位：<span class="span1">{{item.receiveComName}}</span></p>
        <p>去向单编号：<span>{{item.goNo}}</span></p>
        <p>接收时间：<span>{{conversionTime(item.createTime)}}</span></p>
        <p>称重重量：<span>{{item.signVolume ? item.signVolume + 'T' : ''}}</span></p>
        <p>处置方式：<span>{{findquery(item.handleWay, handleWay)}}</span></p>
        <div class="btns">
          <van-button plain type="default" size="small" @click="querydzClick(item.id)">查询去向单</van-button>
        </div>
      </div>
      </van-list>
    </van-pull-refresh>
    <main-tab-bar-user></main-tab-bar-user>
  </div>
</template>

<script>
import MainTabBarUser from '@/components/assembly/Maintabbaruser.vue'
import { orderGolist } from '@/api/qyyorder.js'
import { dictType } from '@/api/notice.js'
export default {
  components: {
    MainTabBarUser
  },
  data () {
    return {
      loading: false, // 上拉加载状态
      finished: false, // 是否已加载完成，加载完成后不再触发load事件进去多次加载请求
      refreshing: false, // 下拉刷新状态
      query: {
        pageNum: 1,
        pageSize: 10,
        receiveComId: undefined, // 接收单位id
        receiveComName: undefined, // 接收单位名称
      },
      total: undefined,
      itemList: [], // 保存订单lsit
      handleWay: undefined, // 处置方式
    }
  },
  created() {
    this.dictType()
  },
  methods: {
    findquery(code, arr) {
      return arr.find(item => item.dictValue == code).dictLabel
    },
    dictType() { // 字典
      dictType('handle_way').then(res => { // 单位类型
        if (res.data.code === 200) {
          this.handleWay = res.data.data
          this.orderGolist()
        }
      })
    },
    orderGolist() { // 去向单List
      orderGolist(this.query).then(res => {
        if(res.data.code === 200) {
          if (this.itemList.length) {
            if (this.loading) { // 上拉加载
              if (res.data.rows.length < 10) { // 没有更多数据
                this.finished = true // 表示上拉加载完毕
              }
              this.itemList = this.itemList.concat(res.data.rows) // 上拉加载新数据添加到数组中
              this.$nextTick(() => { // 在下次 DOM 更新循环结束之后执行延迟回调
                this.loading = false // 关闭上拉加载中
              })
            }
            if (this.refreshing) { // 关闭下拉刷新
              this.refreshing = false // 关闭下拉刷新
              this.itemList = res.data.rows // 重新给数据赋值
            }
          } else {
            this.itemList = res.data.rows
          }
          this.total = res.data.total
        }
      })
    },
    onLoad() { // 上拉加载
      setTimeout(() => {
        if (this.itemList.length < this.total) {
          this.loading = true
          this.query.pageNum++
          this.orderGolist()
        } else {
          this.finished = true
        }
      }, 300)
    },
    onRefresh() { // 下拉刷新
      setTimeout(() => {
        // 下拉刷新后，可以重新触发上拉加载
        this.finished = false;
        // 将 refreshing 设置为 true，表示处于刷新状态
        this.refreshing = true
        this.query.pageNum = 1
        this.orderGolist()
      }, 300)
    },
    queryClick() {
      this.itemList = []
      this.orderGolist()
    },
    querydzClick(id) { // 查看去向单详情
      this.$router.push({path: 'Destinationlistdetails', query: {id: id}})
    }
  }
}

</script>
<style lang="scss" scoped>
.item-list {
  margin: 7px 0;
  padding: 10px;
  border-radius: 3px;
  background-color: #fff;
  .p1 {
    display: flex;
    margin-bottom: 7px;
    font-weight: 600;
    color: #333;
    .span1 {
      font-weight: normal;
    }
  }
  p {
    margin-bottom: 3px;
    color: #999;
    span {
      color: #333;
    }
  }
  .btns {
    display: flex;
    justify-content: flex-end;
  }
}
::v-deep .van-button--small {
  margin-right: 5px;
}
</style>
