<template>
  <div class="tabbar">
    <slot></slot>
  </div>
</template>

<script>
export default {
    name: 'Tabbar'

}
</script>

<style lang="scss" scoped>
  .tabbar {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    height: 49px;
    padding: 3px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0 -1px 3px rgba(100, 100, 100, 0.2);
  }
</style>