import { request } from '../network/request.js'

export function qyyorderList(query) {// 订单 列表
  return request({
    url: '/wechat/runner/order/list',
    method: 'get',
    params: query,
  })
}

export function ordergrab(id) {// 订单 接单
  return request({
    url: '/wechat/runner/order/grab/' + id,
    method: 'post',
  })
}

export function orderconfirm(id) {// 确认订单 提交
  return request({
    url: '/wechat/runner/order/confirm/' + id,
    method: 'post',
  })
}

export function orderdetails(id) {// 订单 详情
  return request({
    url: '/wechat/runner/order/' + id,
    method: 'get',
  })
}

export function product(id) {// 确认订单 获取订单内物品信息
  return request({
    url: '/wechat/runner/order/product/' + id,
    method: 'get',
  })
}

export function productsave(data) {// 确认订单 获取订单内物品信息
  return request({
    url: '/wechat/runner/order/product/save',
    method: 'post',
    data: data
  })
}

export function productdel(id) {// 确认订单 删除订单内物品
  return request({
    url: '/wechat/runner/order/product/' + id,
    method: 'delete',
  })
}

export function orderGolist(query) {// 去向单 列表
  return request({
    url: '/wechat/runner/orderGo/list',
    method: 'get',
    params: query
  })
}

export function orderGoid(id) {// 去向单 详情
  return request({
    url: '/wechat/runner/orderGo/' + id,
    method: 'get',
  })
}

export function orderGoadd(data) {// 去向单 添加
  return request({
    url: '/wechat/runner/orderGo/add',
    method: 'post',
    data: data
  })
}

export function receive(query) {// 接收单位选项
  return request({
    url: '/wechat/common/companyOptions/receive',
    method: 'get',
    params: query
  })
}

export function orderOptions() {// 去向单 可选择的订单
  return request({
    url: '/wechat/runner/orderGo/orderOptions',
    method: 'get',
  })
}
