<template>
  <div class="tab-bar-item" @click="itemClick">
    <div v-if="!isActive"><slot name="item-icon"></slot></div>
    <div v-else><slot name="item-icon-active"></slot></div>
    <div :style="activeStyle"><slot name="item-text"></slot></div>
  </div>
</template>

<script>
export default {
  name: 'TabBarItem',
  props: {
    path: String,
    activeColor: { // 动态绑定颜色
        type: String,
        default: 'red'
    }
  },
  data() {
    return {
    //   isActive: true  // 写死的
    }
  },
  computed: {
    isActive() { // 动态绑定并判断是true还是false， !==-1说明找到了这个url
        return this.$route.path.indexOf(this.path) !== -1
    },
    activeStyle() {
        return this.isActive ? {color: this.activeColor} : {}
    }
  },
  methods: {
    itemClick() {
      this.$router.push(this.path)
    }
  }
}
</script>

<style lang="scss" scoped>
  .tab-bar-item {
    flex: 1;
    text-align: center;
    font-size: 12px;
    color: #333;
  }
  img {
    width: 20px;
    height: 20px;
    margin-top: 3px;
  }
</style>
